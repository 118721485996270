import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import {
    anonymizeOtherParty,
    FILE_MESSAGE_TEXT_CONTENT,
    getUserTxRole,
    resolveOtherPartyData,
} from '../../../util/transaction';
import { Avatar, Tooltip, UserDisplayName } from '../../../components';

import css from './InboxItem.css';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../../components/Tooltip/Tooltip';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { formatDate, getFormattedDate, txState } from './InboxItem.helpers';
import { resolveTransitionMessage } from '../../../components/TransitionMessage/resolveTransitionMessage';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { ARCHIEVED_FILTER_KEY, FILTER_SEARCH_PARAM } from './Filters';
import { hasNewMessage, hasNewTransition } from '../InboxPanel.helpers';
import { ensureUser } from '../../../util/data';
import { organizeItems } from '../../../components/ActivityFeed/ActivityFeed.helpers';
import { chatItemFabric } from '../../../components/ActivityFeed/ActivityFeed.helpers';
import { CHAT_ENTITY_TYPE_APPOINTMENT } from '../TransactionPage.duck';

export const InboxItem = ({
    tx,
    currentUser,
    allUserMessages,
    setInboxPanelVisible,
    activeArchivePanelId,
    setActiveArchivePanelId,
    isSelected,
    onArchieveChat,
    appointmentEntities: appointmentEntitiesData,
    panelRef,
    filters,
    history,
    intl,
}) => {
    const [isMobile, , computing] = useIsMobile(1024);
    const [isUnread, setIsUnread] = useState(false);
    const [actionRequired, setActionRequired] = useState(false);
    const [showItem, setShowItem] = useState(false);

    const currentProvider = ensureUser(tx.provider);
    const currentCustomer = ensureUser(tx.customer);

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(currentUser, currentCustomer, currentProvider)
    )(tx);

    const {
        attributes: {
            profile: {
                protectedData: { archievedTransactions = {} },
            },
        },
    } = currentUser;

    const {
        attributes: { lastTransition, transitions },
        id: { uuid },
    } = tx;

    const isArchived = archievedTransactions[uuid];
    const hasFilters = filters && filters.length > 0;
    const hasArchieveFilterEnabled = hasFilters && filters.includes(ARCHIEVED_FILTER_KEY);

    const unreadMessages = allUserMessages[uuid];
    const appointmentEntities = appointmentEntitiesData[uuid] || [];

    useEffect(() => {
        /**
         * dearchieve the chat on a new message received in the chat
         */
        if (unreadMessages && isArchived) {
            const dearchieveChat = unreadMessages.some(({ attributes: { createdAt } }) => {
                const createdAtDate = new Date(createdAt);
                const archievedTransactionDate = new Date(isArchived.updatedAt);

                return createdAtDate > archievedTransactionDate;
            });
            if (dearchieveChat) {
                onArchieveChat(uuid, 'remove');
            }
        }

        setShowItem(isSelected || (hasArchieveFilterEnabled ? isArchived : !isArchived));
    }, [isSelected, isArchived, unreadMessages, hasArchieveFilterEnabled]);

    useEffect(() => {
        /** mark tx item as the one which has unread message or tx action */
        setIsUnread(hasNewMessage(tx, allUserMessages, currentUser));
        setActionRequired(hasNewTransition(tx, currentUser));
    }, [currentUser, allUserMessages, tx]);

    const isAppointmentInitialMessage = ({ data, type }) =>
        data && data.message && type === CHAT_ENTITY_TYPE_APPOINTMENT;

    const organizedItems = organizeItems(
        allUserMessages[uuid] || [],
        transitions.find(t => t.transition === lastTransition),
        appointmentEntities.filter(
            ({ transition, data, type }) =>
                !!transition || isAppointmentInitialMessage({ data, type })
        )
    );

    const stateData = txState(tx);
    const ownRole = getUserTxRole(currentUser.id, tx);
    const lastMessage = organizedItems[organizedItems.length - 1];

    otherParty.attributes.profile.displayName =
        otherParty.attributes.profile.displayName &&
        otherParty.attributes.profile.displayName
            .split(' ')
            .splice(0, 1)
            .join('');

    const otherUserDisplayName = (
        <UserDisplayName
            user={otherParty}
            intl={intl}
            shouldBeTrimmed={otherParty.attributes.profile.isAnonym}
        />
    );
    const isOtherUserBanned = otherParty.attributes.banned;

    const lastTransitionedAtFormatted = chatItemFabric({
        item: lastMessage,
        messageComponent: ({ attributes: { createdAt } }) => formatDate(new Date(createdAt)),
        transitionComponent: ({ createdAt }) => formatDate(new Date(createdAt)),
        appointmentComponent: ({ createdAt }) => formatDate(new Date(createdAt)),
    });

    const linkClasses = classNames(css.itemLink, {
        [css.bannedUserLink]: isOtherUserBanned,
    });

    const archivePanelOpen = activeArchivePanelId === uuid;

    const redirectTo = (pageName, params = {}) =>
        history.replace(
            createResourceLocatorString(
                pageName,
                routeConfiguration(),
                { ...params },
                hasFilters
                    ? {
                          [FILTER_SEARCH_PARAM]: filters.join(encodeURIComponent(',')),
                      }
                    : {}
            )
        );
    const isFile = content =>
        content === FILE_MESSAGE_TEXT_CONTENT
            ? intl.formatMessage({
                  id: 'InboxPage.fileSent',
              })
            : content;

    /** SUB-COMPONENT */
    const UserAvatar = () => (
        <div className={css.itemAvatar}>
            <Avatar user={otherParty} />
        </div>
    );
    /** SUB-COMPONENT */
    const NewMessageBadge = () =>
        actionRequired ? (
            <p className={css.newActionBadge}>
                <FormattedMessage id="InboxPanel.newActionNeeded" />
            </p>
        ) : null;
    /** SUB-COMPONENT */
    const UserName = () => (
        <p className={css.itemUsername}>
            {otherUserDisplayName} <NewMessageBadge />
        </p>
    );
    /** SUB-COMPONENT */
    const BookingMessage = () => (
        <p
            className={classNames(css.bookingNoActionNeeded, {
                [css.bookingActionNeeded]: isUnread || actionRequired,
            })}
        >
            {lastMessage &&
                chatItemFabric({
                    item: lastMessage,
                    messageComponent: message => isFile(message.attributes.content),
                    transitionComponent: transition =>
                        resolveTransitionMessage({
                            currentTransaction: tx,
                            ownRole,
                            transition,
                            intl,
                            currentUser,
                            suppressLinks: true,
                        }),
                    appointmentComponent: appointment =>
                        isAppointmentInitialMessage(appointment || {})
                            ? intl.formatMessage({
                                  id: 'InboxPage.appointmentSent',
                              })
                            : appointment && !appointment.transition
                            ? resolveTransitionMessage({
                                  currentTransaction: tx,
                                  ownRole,
                                  intl,
                                  currentUser,
                                  ...appointment,
                                  by: appointment.sender.userRole,
                              })
                            : null,
                })}
        </p>
    );
    /** SUB-COMPONENT */
    const BookingStatusBadge = () => (
        <div className={classNames(css.stateName, stateData.stateClassName)}>
            {intl.formatMessage({
                id: stateData.state,
            })}
        </div>
    );
    /** SUB-COMPONENT */
    const LastTransitionInfo = () => (
        <p
            className={css.lastTransitionedAt}
            title={getFormattedDate(lastTransitionedAtFormatted.short)}
        >
            {lastTransitionedAtFormatted.long}
        </p>
    );
    /** SUB-COMPONENT */
    const ArchieveBadge = () => (
        <Tooltip
            isStatic
            staticTooltipState={archivePanelOpen}
            outsideClickHandler={() => setActiveArchivePanelId(null)}
            tooltipClassName={classNames(css.controls, {
                [css.isArchived]: isArchived,
            })}
            rootClassName={css.tooltipWrapper}
            parentContainerRef={panelRef}
            content={
                <ul>
                    <li
                        onClick={e => {
                            e.stopPropagation();
                            onArchieveChat(uuid, isArchived ? 'remove' : 'add').then(() =>
                                redirectTo('OrderMessagesPage')
                            );
                        }}
                    >
                        <FormattedMessage
                            id={`InboxPanel.${isArchived ? 'dearchieveAction' : 'archieveAction'}`}
                        />
                    </li>
                </ul>
            }
        >
            <aside
                onClick={e => {
                    e.stopPropagation();
                    setActiveArchivePanelId(archivePanelOpen ? null : uuid);
                }}
                className={css.archieveLink}
            >
                <span className={css.ellipsis} data-role={TOOLTIP_DATA_REQUEST_ATTR}>
                    ...
                </span>
            </aside>
        </Tooltip>
    );

    return showItem && !computing ? (
        <li
            key={uuid}
            className={classNames({
                [css.listItem]: true,
                [css.isSelected]: isSelected || actionRequired,
            })}
        >
            <div
                className={css.item}
                onClick={() => {
                    isMobile && setInboxPanelVisible(false);
                }}
            >
                <UserAvatar />

                <div
                    className={linkClasses}
                    onClick={() => redirectTo('OrderDetailsPage', { id: tx.id.uuid })}
                >
                    {isMobile ? (
                        <>
                            <div className={css.itemInfoMob}>
                                <UserName />
                                <LastTransitionInfo />
                            </div>
                            <div className={css.itemInfo}>
                                <BookingMessage />
                            </div>
                            <div className={css.itemStateMob}>
                                <NewMessageBadge />
                                <BookingStatusBadge />
                                <ArchieveBadge />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={css.itemInfo}>
                                <UserName />
                                <BookingMessage />
                            </div>
                            <div className={css.itemState}>
                                <BookingStatusBadge />
                                <LastTransitionInfo />
                            </div>
                        </>
                    )}
                </div>

                {!isMobile && <ArchieveBadge />}
            </div>
        </li>
    ) : null;
};
