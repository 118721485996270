import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkMarketplaceCurrentUser, ensureUser, ensureCurrentUser } from '../../util/data';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import {
    loadData,
    sendUserExternalReview,
    getUserExternalReview,
    querExternalReviewsError,
} from '../../containers/ProfilePage/ProfilePage.duck';
import {
    changeEntityAssetMetadata,
    getEntityAssets,
    removeEntityAssets,
    uploadEntityAssets,
} from '../../ducks/Assets.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ArrowNextIcon } from '../../icons';
import { TopbarContainer } from '../../containers';
import { ExternalReviewForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

import Recommendations from './Recommendations';
import Gallery from './Gallery';
import css from './EditUserInfoPage.css';
import { ONBOARDING_INVITES_SENT } from '../OnboardingPage/OnboardingPage';
import { sendRecommendations } from '../../util/api';

export const ADD_RECOMMENDATIONS_FORM = 'add-recommendations';
export const GET_RECOMMENDATIONS_FORM = 'empfehlungen';
export const GALLERY_FORM = 'gallery';

const { UUID } = sdkTypes;
const { userTypeRider } = config;

const EditUserInfoPage = ({
    onUpdateProfile,
    onSendUserExternalReview,
    onGetUserExternalReview,
    onQuerExternalReviewsError,
    onChangeEntityAssetMetadata,
    onUploadAssetsRequest,
    onRemoveAssetsRequest,
    onAssetsRequest,
    uploadImageError,
    uploadInProgress,
    currentUser,
    publicUser,
    userShowLoading,
    intl,
    history,
    location,
    externalReviewsErrors,
    externalReviewsRequests,
    externalReviewsData,
    // userDocErrorMetadata,
    assetsLoadingRequests,
    assetsErrors,
    assetsData,
    match: {
        params: { section, userNameToRecommend, userId },
    },
}) => {
    const schemaTitle = intl.formatMessage({ id: 'EditUserInfoPage.schemaTitle' });
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const {
        id,
        attributes: { profile },
    } = ensuredCurrentUser;
    const { firstName, publicData = {} } = profile || {};
    const { uuid } = id || { uuid: null };

    const uploadAssetsInProgress = assetsLoadingRequests && uuid && assetsLoadingRequests[uuid];

    const editSectionConfig = {
        [GET_RECOMMENDATIONS_FORM]: (
            <Recommendations
                onSubmit={({ recipientsList, message }) => {
                    /**
                     * if a rider sent invites,
                     * show him a notification-modal on the confirmation page
                     */
                    sessionStorage.setItem(ONBOARDING_INVITES_SENT, ONBOARDING_INVITES_SENT);

                    const redirectToProfilePage = () =>
                        history.push(
                            createResourceLocatorString(
                                'ProfileSettingsPage',
                                routeConfiguration(),
                                { userType: publicData.userType || userTypeRider },
                                {}
                            )
                        );

                    if (recipientsList && recipientsList.length && message) {
                        sendRecommendations(JSON.stringify({ recipientsList, message }));
                        redirectToProfilePage();
                    }
                }}
            />
        ),
        [GALLERY_FORM]: (
            <Gallery
                currentUser={currentUser}
                uploadImageError={uploadImageError}
                uploadInProgress={uploadInProgress}
                onSubmit={values => {
                    onUpdateProfile({}, values);
                }}
                location={location}
                onUploadAssetsRequest={onUploadAssetsRequest}
                onAssetsRequest={onAssetsRequest}
                assetsErrors={assetsErrors}
                // userDocErrorMetadata={userDocErrorMetadata}
                uploadAssetsInProgress={uploadAssetsInProgress}
                onChangeEntityAssetMetadata={onChangeEntityAssetMetadata}
                onRemoveAssetsRequest={onRemoveAssetsRequest}
                assetsData={assetsData}
            />
        ),
        [ADD_RECOMMENDATIONS_FORM]: (
            <ExternalReviewForm
                userId={userId}
                currentUser={currentUser}
                publicUser={publicUser}
                externalReviewsErrors={externalReviewsErrors}
                externalReviewsRequests={externalReviewsRequests}
                userShowLoading={userShowLoading}
                externalReviewsData={externalReviewsData}
                onSendUserExternalReview={onSendUserExternalReview}
                onGetUserExternalReview={onGetUserExternalReview}
                onQuerExternalReviewsError={onQuerExternalReviewsError}
                onSubmit={onSendUserExternalReview}
                history={history}
            />
        ),
    };

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={false}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                name: schemaTitle,
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain} blobBackground>
                    <div className={css.root}>
                        {!(assetsLoadingRequests && assetsLoadingRequests[uuid]) && (
                            <div className={css.backButton} onClick={() => history.goBack()}>
                                <ArrowNextIcon />
                                <FormattedMessage id="EditUserInfoPage.prevStep" />
                            </div>
                        )}
                        {userNameToRecommend
                            ? editSectionConfig[ADD_RECOMMENDATIONS_FORM]
                            : editSectionConfig[section] || null}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = state => {
    const currentUser = checkMarketplaceCurrentUser(state);
    const { ProfileSettingsPage, ProfilePage, OnboardingPage } = state;
    const { uploadImageError, uploadInProgress } = ProfileSettingsPage;

    const {
        userId,
        userShowLoading,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
    } = ProfilePage;

    // const { uploadDocumentsInProgress, userDocErrorMetadata } = OnboardingPage;
    const { assetsLoadingRequests, assetsData, assetsErrors } = state.Assets;

    const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);

    const publicUser = ensureUser(userMatches.length === 1 ? userMatches[0] : null);

    return {
        currentUser,
        publicUser,
        userShowLoading,
        uploadImageError,
        uploadInProgress,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
        // userDocErrorMetadata,
        // uploadDocumentsInProgress,
        assetsErrors,
        assetsLoadingRequests,
        assetsData,
    };
};

const mapDispatchToProps = dispatch => ({
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
    onSendUserExternalReview: data => dispatch(sendUserExternalReview(data)),
    onGetUserExternalReview: userReviewedId => dispatch(getUserExternalReview(userReviewedId)),
    onQuerExternalReviewsError: error => dispatch(querExternalReviewsError(error)),
    onUploadAssetsRequest: data => dispatch(uploadEntityAssets({ ...data, type: 'asset' })),
    onAssetsRequest: id => dispatch(getEntityAssets({ id })),
    onRemoveAssetsRequest: data => dispatch(removeEntityAssets(data)),
    onChangeEntityAssetMetadata: data => dispatch(changeEntityAssetMetadata(data)),
});

EditUserInfoPage.loadData = params => {
    const id = new UUID(params.userId);
    return loadData(id);
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(EditUserInfoPage);
