import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { Tooltip } from '../../../components';

import { USER_EXPERINCE } from '../EditUserInfoModalSectionConstants';

import { RoundInfoIcon } from '../../../icons';

import css from './EditUserMainInfoSection.css';

const ExperienceSection = ({
    sectionClassName,
    isPublic,
    handleEditSection,
    renderEditPencilIcon,
    user,
    intl,
}) => {
    const { publicData, displayName } = user.attributes.profile;
    const [firstName] = (displayName || '').split(' ');
    const { experience: userExperience, experienceRegularity, experienceFocus } = publicData;

    const experienceRegularityLabel = intl.formatMessage({
        id: `ProfileSettingsForm.experienceSecondaryInfoLabel-${experienceRegularity ||
            'irregular'}`,
    });

    const experienceFocusLabel = intl.formatMessage({
        id: `ProfileSettingsForm.experienceSecondaryInfoLabel-${experienceFocus || 'irregular'}`,
    });

    const experienceLabel = `${userExperience} ${intl.formatMessage({
        id: `ProfileSettingsPage.experience-${userExperience <= 1 ? 'single' : 'multiple'}`,
    })}`;

    return (
        <>
            {userExperience && (
                <div
                    className={classNames(css.sectionItem, {
                        [css.editExperience]: true,
                        [sectionClassName]: !!sectionClassName,
                        [css.sectionItemPublic]: isPublic,
                    })}
                    onClick={() => {
                        if (isPublic) return;
                        handleEditSection(USER_EXPERINCE);
                    }}
                >
                    {userExperience} <FormattedMessage id="ProfileSettingsPage.userExp" />{' '}
                    {renderEditPencilIcon(USER_EXPERINCE, css.smallIcon, null, {
                        onClick: () => null,
                    })}
                    {isPublic && experienceRegularity && experienceFocus && (
                        <Tooltip
                            tooltipClassName={css.experienceTooltip}
                            rootClassName={css.experienceTooltipWrapper}
                            content={
                                <p>
                                    <FormattedMessage
                                        id="ProfileSettingsPage.userExpTooltipContent"
                                        values={{
                                            userName: firstName,
                                            experienceRegularity: experienceRegularityLabel,
                                            experienceFocus: experienceFocusLabel,
                                            experience: experienceLabel,
                                        }}
                                    />
                                </p>
                            }
                        >
                            <RoundInfoIcon />
                        </Tooltip>
                    )}
                </div>
            )}
            {!userExperience && !isPublic && (
                <div
                    onClick={() => handleEditSection(USER_EXPERINCE)}
                    className={classNames(css.sectionItem, css.editExperience, {
                        [sectionClassName]: !!sectionClassName,
                    })}
                >
                    <FormattedMessage id="ProfileSettingsPage.userExpAbsent" />
                </div>
            )}
        </>
    );
};

export default ExperienceSection;
