import React, { useState, useEffect } from 'react';
import { any, bool, func, object, string, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import classNames from 'classnames';
import { Button, ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { hash53 } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { NotSubscribedIcon, SubscribedIcon } from '../../icons/SearchAgentIcons.js';
import css from './SearchAgent.css';
import InterruptProcessModal from '../InterruptProcessModal/InterruptProcessModal';

const NOT_SUBSCRIBED = 'notSubscribedYet';
const ALREADY_SUBSCRIBED = 'alreadySubscribed';

const iconConfig = {
    [NOT_SUBSCRIBED]: {
        icon: () => <NotSubscribedIcon />,
    },
    [ALREADY_SUBSCRIBED]: {
        icon: () => <SubscribedIcon />,
    },
};

const SearchAgentComponent = ({
    currentUser,
    urlQueryParams,
    initialType,
    history,
    inProgress,
    updateError,
    onUpdateUserProfileInfo,
}) => {
    // const [componentKey, setComponentKey] = useState('initial');
    const [modalVisible, setModalVisibility] = useState(false);

    // useEffect(() => {
    //     setComponentKey(new Date().toString());
    // }, [urlQueryParams]);

    const userDefined = currentUser && currentUser.id && currentUser.id.uuid;
    // const publicData = userDefined ? currentUser.attributes.profile.publicData : {};
    const protectedData = userDefined ? currentUser.attributes.profile.protectedData : {};

    // const { listingType } = parse(location.search);
    const { searchGroups } = protectedData;
    // const isListingTypeRider = listingType === listingTypeRider;
    // const isRider = userType === userTypeRider;
    // const riderSearchesForRiders = isListingTypeRider && isRider;

    const isDisabled = !userDefined; // || isListingTypeRider;

    const validParams = Object.entries(urlQueryParams).reduce((acc, [key, value]) => {
        /** remove utm_ like query params which are
         * added automatically after redirecting from gmail */
        const noGmailParams = !key.includes('utm_');
        const noNullParams = !!value;
        noNullParams && noGmailParams && (acc[key] = value);
        return acc;
    }, {});

    useEffect(() => {
        const { bounds, origin } = validParams;
        /** if there are no bounds neither origin, it is a map search case,
         *  and raw origin query string has to be converted into bounds;
         *
         * the opposite scenario is carried out by createSearchAgentGroup function
         * when a new search group is created
         */
        if (!bounds && !origin) {
            const rawUrlSearchParams = new URLSearchParams(window.location.search);
            const { origin: rawOrigin } = Object.fromEntries(rawUrlSearchParams.entries());

            const routes = routeConfiguration();
            const routeName = 'SearchPage';

            if (rawOrigin) {
                const validURI = createResourceLocatorString(
                    routeName,
                    routes,
                    {},
                    { ...validParams, bounds: rawOrigin }
                );
                history.replace(validURI);
            }
        }
    }, []);

    const searchGroupHash = hash53(JSON.stringify(validParams));
    const searchGroupAlreadyExists = searchGroups && searchGroups[searchGroupHash];
    const newSearchGroupMaybe = !searchGroups || !searchGroups[searchGroupHash];

    const type = initialType || (searchGroupAlreadyExists ? ALREADY_SUBSCRIBED : NOT_SUBSCRIBED);

    const createSearchAgentGroup = () => {
        const searchGroupsUpdated = { ...searchGroups };

        if (searchGroupAlreadyExists) {
            delete searchGroupsUpdated[searchGroupHash];
        } else if (newSearchGroupMaybe) {
            searchGroupsUpdated[searchGroupHash] = {
                searchParams: {
                    ...validParams,
                },
                lastViewed: new Date().toString(),
            };
            /** if user moves map and saves search group,
             * use bounds in url where mapSearch=true param is present - bound & origin
             * if user selects value at location field and saves search group,
             * use location bounds as place origin instead - bound & !origin
             */
            const mapSearch = validParams.bounds && validParams.origin;

            if (!mapSearch) {
                const SG = searchGroupsUpdated[searchGroupHash];
                SG.searchParams.origin = SG.searchParams.bounds;
                delete SG.searchParams.bounds;
            }

            const { price } = searchGroupsUpdated[searchGroupHash].searchParams;

            if (price) {
                searchGroupsUpdated[searchGroupHash].searchParams.price = price
                    .split(',')
                    .map(p => p * 100)
                    .join(',');
            }
        }

        const params = {
            protectedData: { ...protectedData, searchGroups: { ...searchGroupsUpdated } },
        };

        onUpdateUserProfileInfo(params).then(() => {
            if (modalVisible) {
                setModalVisibility(false);
            }
        });
    };

    const handleClick = () => {
        if (!currentUser) {
            return history.push(
                createResourceLocatorString('SignupPage', routeConfiguration(), {}, {})
            );
        }

        if (inProgress || isDisabled) {
            return;
        }

        setModalVisibility(true);
    };

    const classes = classNames({
        [css.container]: true,
        [css.inProgress]: inProgress,
        [css.alreadySubscribed]: searchGroupAlreadyExists,
        [css.disabled]: isDisabled,
    });

    return (
        <div>
            <Button className={classes} onClick={handleClick} type="button">
                {iconConfig[type].icon()}
            </Button>
            {searchGroupAlreadyExists ? (
                <InterruptProcessModal
                    id="SearchAgentComponentModal.searchGroupAlreadyExists"
                    isOpen={modalVisible}
                    onClose={() => setModalVisibility(false)}
                    handleInterruption={createSearchAgentGroup}
                    descriptionId="SearchAgent.removeSearchGroupDescription"
                    sidenoteId="SearchAgent.removeSearchGroupSidenote"
                />
            ) : (
                <ModalPortal
                    id="SearchAgentComponentModal"
                    isOpen={modalVisible}
                    onClose={() => setModalVisibility(false)}
                    containerClassName={css.modalContainer}
                    containerClassNameJoined
                >
                    <h3 className={css.modalHeader}>
                        <FormattedMessage id="SearchAgent.createSearchGroupTitle" />
                    </h3>
                    <p className={css.modalParagraph}>
                        <FormattedMessage id="SearchAgent.createSearchGroupDescription" />
                    </p>
                    <p className={css.modalSmallText}>
                        <FormattedMessage id="SearchAgent.createSearchGroupSmallText" />
                    </p>
                    <Button
                        type="button"
                        className={css.modalActionButton}
                        onClick={createSearchAgentGroup}
                        inProgress={inProgress}
                        disabled={inProgress}
                    >
                        <FormattedMessage id="SearchAgent.createSearchGroupActionButton" />
                    </Button>
                    <span className={css.updateError}>{updateError}</span>
                </ModalPortal>
            )}
        </div>
    );
};

SearchAgentComponent.propTypes = {
    currentUser: propTypes.currentUser,
    urlQueryParams: object.isRequired,
    initialType: string,
    inProgress: bool,
    updateError: any,
    onUpdateUserProfileInfo: func.isRequired,
    history: shape({
        push: func.isRequired,
    }).isRequired,
};

export default compose(withRouter)(SearchAgentComponent);
