import React from 'react';
import { SecondaryButton, FieldRadioButton } from '../../../components';
import css from './EditUserAvailabilityStepReasonToClose.css';

const options = [
    { label: 'Keine Anfragen erhalten', value: 'Keine Anfragen erhalten' },
    { label: 'Match hier gefunden', value: 'Match hier gefunden' },
    { label: 'Match woanders gefunden', value: 'Match woanders gefunden' },
    { label: 'Anderer Grund', value: 'Anderer Grund' },
];

export default ({ values, form, parentSubmit }) => (
    <>
        <p>Wähle einen Grund aus:</p>
        {options.map(({ label, value }, index) => (
            <FieldRadioButton
                key={index}
                form={form}
                id={`${label}-option`}
                name="availabilityReasonToClose"
                label={label}
                value={value}
            />
        ))}

        <footer className={css.footer}>
            <p>
                Wenn dein Profil „Nicht verfügbar“ ist, wirst Du nicht mehr in den Suchergebnissen
                angezeigt.{' '}
                <a
                    target="_blank"
                    href="https://support.horsedeal.com/hc/de/articles/18475954019602-Wie-steuere-ich-meine-Sichtbarkeit"
                >
                    Mehr erfahren
                </a>
            </p>

            <SecondaryButton
                disabled={!values.availabilityReasonToClose}
                onClick={() => parentSubmit(values)}
            >
                Änderung bestätigen
            </SecondaryButton>
        </footer>
    </>
);
