import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';

import { Form, FieldRadioButton, SecondaryButton } from '../../components';

import { ModalPortal } from '../../components';
import css from './CloseListingModal.css';

const CloseListingModal = ({ onClose, onSubmit, intl }) => (
    <ModalPortal
        id="close-listing-modal"
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        isOpen
        onClose={onClose}
        containerClassNameJoined
        isSticky
    >
        <h3 className={css.modalTitle}>Inserat pausieren?</h3>
        <p className={css.modalMessage}>
            Erzähle uns, warum Du dein Inserat gerne pausieren möchtest.
        </p>
        <p>Wähle einen Grund aus:</p>
        <FinalForm
            onSubmit={onSubmit}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;

                const { reasonToCloseListing } = values;

                return (
                    <Form onSubmit={handleSubmit}>
                        {['no-requests', 'rider-found-here', 'rider-found-elsewhere', 'other'].map(
                            option => (
                                <FieldRadioButton
                                    key={option}
                                    form={form}
                                    id={`${option}-option`}
                                    name="reasonToCloseListing"
                                    label={intl.formatMessage({
                                        id: `CloseListingModal.option-${option}`,
                                    })}
                                    value={intl.formatMessage({
                                        id: `CloseListingModal.option-${option}`,
                                    })}
                                />
                            )
                        )}

                        <footer>
                            <p className={css.sideNote}>
                                Wenn dein Inserat pausiert ist, wird es nicht mehr in den
                                Suchergebnissen angezeigt.{' '}
                                <a
                                    href="https://support.horsedeal.com/hc/de/articles/18607472467986-Wie-kann-ich-mein-Inserat-pausieren"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Mehr erfahren
                                </a>
                            </p>
                            <SecondaryButton
                                type="submit"
                                className={css.submitButton}
                                disabled={!reasonToCloseListing}
                            >
                                Inserat pausieren
                            </SecondaryButton>
                        </footer>
                    </Form>
                );
            }}
        />
    </ModalPortal>
);

export default injectIntl(CloseListingModal);
