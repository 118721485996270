import React from 'react';
import Confetti from 'react-confetti';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { discardNotificationModalData } from '../../ducks/UI.duck';
import { fetchCurrentUser, updateUserProfileInfo } from '../../ducks/user.duck';
import { NamedLink, ModalPortal, Button, SecondaryButton } from '../../components';
import { IconPresentXXL } from '../../icons';

import css from './CreditsNotificationModal.css';

const windowDefined = typeof window !== 'undefined';

const CreditsNotificationModal = ({
    currentUser,
    notificationModal,
    onFetchingCurrentUser,
    onUpdateUserProfileInfo,
    onDiscardingNotificationModalData,
}) => {
    const isLoggedIn = currentUser && currentUser.id;
    const isOpen = notificationModal && notificationModal.show && isLoggedIn;

    const onClose = async () => {
        const currentUserDataUpd = { ...currentUser };
        currentUserDataUpd.attributes.profile.privateData.creditsRecentEvent = null;
        await onUpdateUserProfileInfo(
            { privateData: { creditsRecentEvent: null } },
            currentUserDataUpd
        );
        onDiscardingNotificationModalData();
        /** show updated credits info */
        onFetchingCurrentUser();
    };

    return (
        isOpen && (
            <ModalPortal
                id="CreditsNotificationModal"
                isOpen
                onClose={onClose}
                contentClassName={css.modalContent}
                slot={
                    <Confetti
                        width={windowDefined ? window.innerWidth : 1000}
                        height={windowDefined ? window.innerHeight : 1000}
                        style={{ zIndex: 999 }}
                    />
                }
            >
                <IconPresentXXL />
                <h3>{notificationModal.heading}</h3>
                <p>{notificationModal.description}</p>
                <footer>
                    <NamedLink name="BalancePage">
                        <Button
                            type="button"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Meine Bilanz
                        </Button>
                    </NamedLink>
                    <SecondaryButton
                        type="button"
                        onClick={() => {
                            window.open(
                                'https://support.horsedeal.com/hc/de/categories/18474012341906',
                                '_blank'
                            );
                        }}
                    >
                        Was sind Credits?
                    </SecondaryButton>
                </footer>
            </ModalPortal>
        )
    );
};

const mapStateToProps = ({ user: { currentUser }, UI: { notificationModal } }) => ({
    currentUser,
    notificationModal,
});

const mapDispatchToProps = dispatch => ({
    onDiscardingNotificationModalData: () => dispatch(discardNotificationModalData()),
    onFetchingCurrentUser: () => dispatch(fetchCurrentUser()),
    onUpdateUserProfileInfo: (params, dataToInsert) =>
        dispatch(updateUserProfileInfo(params, dataToInsert)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CreditsNotificationModal);
