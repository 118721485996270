import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { SocialLoginButton } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import css from './SocialLoginButtons.css';
import { FacebookLogo, GoogleLogo } from '../../icons';
import classNames from 'classnames';

const windowDefined = typeof window !== 'undefined';
const isNative = windowDefined && (window.isNative || window.ReactNativeWebView);

const SocialLoginButtons = ({
    showGoogleLogin,
    showFacebookLogin,
    rootClassName,
    buttonsHolderClassName,
    defaultConfirmSearch = '',
}) => {
    const getDefaultRoutes = () => {
        const routes = routeConfiguration();
        const baseUrl = apiBaseUrl();

        // // Route where the user should be returned after authentication
        // // This is used e.g. with EditListingPage and ListingPage
        // const fromParam = from ? `from=${from}` : '';

        // Default route where user is returned after successfull authentication
        const defaultReturn = pathByRouteName('IdpAuthSuccessPage', routes);
        const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

        // Route for confirming user data before creating a new user
        const defaultConfirm = pathByRouteName('ConfirmPage', routes);

        const defaultConfirmParam = defaultConfirm
            ? `&defaultConfirm=${defaultConfirm}${defaultConfirmSearch}`
            : '';

        return { baseUrl, defaultReturnParam, defaultConfirmParam };
    };

    const authWithFacebook = () => {
        if (isNative) {
            /**
             * handle cases when the app is opened not in the browser
             * but inside e.g. a mobile app
             */
            if (!window.ReactNativeWebView) console.warn('window.ReactNativeWebView is undefined');

            window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage('facebookLoginClicked');
        } else {
            const defaultRoutes = getDefaultRoutes();
            const { baseUrl, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/facebook?${defaultReturnParam}${defaultConfirmParam}`;
        }
    };

    const authWithGoogle = () => {
        if (isNative) {
            /**
             * handle cases when the app is opened not in the browser
             * but inside e.g. a mobile app
             */
            if (!window.ReactNativeWebView) console.warn('window.ReactNativeWebView is undefined');

            window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage('googleLoginClicked');
        } else {
            const defaultRoutes = getDefaultRoutes();
            const { baseUrl, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/google?${defaultReturnParam}${defaultConfirmParam}`;
        }
    };

    return (
        <div className={rootClassName || css.root}>
            <section
                className={classNames(css.idpButtons, {
                    [buttonsHolderClassName]: !!buttonsHolderClassName,
                })}
            >
                {showGoogleLogin && (
                    <div>
                        <SocialLoginButton onClick={() => authWithGoogle()}>
                            <span className={css.buttonIcon}>
                                <GoogleLogo />
                            </span>
                            <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
                        </SocialLoginButton>
                    </div>
                )}
                {showFacebookLogin && (
                    <div>
                        <SocialLoginButton onClick={() => authWithFacebook()}>
                            <span className={css.buttonIcon}>
                                <FacebookLogo />
                            </span>
                            <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
                        </SocialLoginButton>
                    </div>
                )}
            </section>

            <div className={css.socialButtonsOr}>
                <span className={css.socialButtonsOrText}>
                    <FormattedMessage id="AuthenticationPage.or" />
                </span>
            </div>
        </div>
    );
};

export default SocialLoginButtons;
